const IS_DEVELOPMENT = [process.env.NODE_ENV, process.env.GATSBY_ENV].includes('development')

const STORYBLOK_TOKEN = 'KQKzQcM53M9AqjQVOZURWQtt'
const RESOLVE_RELATIONS = [
  'global-component.reference',
  'blog-post.category',
  'blog-post.author',
  'blog-post.tags',
  'blog-list-item.resource',
]

const GTM_ID = 'GTM-M84HVX3'
const HUBSPOT_PORTAL_ID = '6880068'

const getPagePath = (full_slug) => {
  return full_slug === 'index' || full_slug === '/'
    ? '/'
    : `/${full_slug.replace('/index', '').replace(/^\/+/, '').replace(/\/+$/, '')}/`
}

const getLayoutUuid = (story) =>
  story?.content?.layout || story?.content?.category?.content?.articlesLayout

// eslint-disable-next-line no-warning-comments
// DO NOT TOUCH - Populate by prebuild.js
const SITE_NAME = 'Seiza'
const SITE_URL = 'https://www.seiza.co'

module.exports = {
  GTM_ID,
  HUBSPOT_PORTAL_ID,
  IS_DEVELOPMENT,
  RESOLVE_RELATIONS,
  SITE_NAME,
  SITE_URL,
  STORYBLOK_TOKEN,
  utils: {
    getLayoutUuid,
    getPagePath,
  },
}
