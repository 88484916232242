/* eslint-disable no-warning-comments */

import './src/styles/globals.scss'

import * as React from 'react'

import {loadScripts} from '@/common/scripts'
import {PageTransition, PageTransitionWrapper, TRANSITION_EXIT} from '@/components/PageTransition'

export const wrapPageElement = ({element, props}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    loadScripts()
  }, [])
  return (
    <>
      <PageTransition {...props} />
      <PageTransitionWrapper initial={false}>{element}</PageTransitionWrapper>
    </>
  )
}

export const shouldUpdateScroll = ({routerProps: {location}, getSavedScrollPosition}) => {
  const duration = TRANSITION_EXIT * 1000

  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), duration)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), duration)
  }

  return false
}
